import webmarko from '../assets/png/webmarko.jpg';
import societeGenerale from '../assets/png/sgma.jpg';
import jdiSoft from '../assets/png/jdi.jpg';
import abiSoft from '../assets/png/abi.png';

export const aboutData = {
    title: "Who I am?",
    description1: "My name's Boufnichel. I'm a software engineer and full stack developer based in Fez, Morocco.",
    description2: "I've been working on many professional projects, which have allowed me to improve my software engineering skills and become a strong candidate. Right now, I'm focusing on improving my system design skills to ultimately produce high-quality IT products.",
    image: 2,
    companies: [
        {
            name: "Société Générale Maroc",
            logo: societeGenerale
        },
        {
            name: "WebMarko",
            logo: webmarko
        },
        {
            name: "JDI Soft",
            logo: jdiSoft
        },
        {
            name: "ABI Soft",
            logo: abiSoft
        }
    ]
}